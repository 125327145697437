import React from "react";

export const Pitch: React.FC = () => {
  return (
      <p className="is-size-5 has-text-justified has-text-weight-light is-family-code">
        My goal is to drive innovative solutions with technology to satisfy
        people modern needs.
      </p>
  );
};
